.service-group {
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
}
.service-search{
    border: 1px solid #3C4F74;
    border-radius: 40px;
    margin: 14px;
    margin-bottom: 22px;
    width: 68vw;
    z-index: 9999;
}
.service-item {
    display: flex;
    flex-direction: row;
    padding:5px;
    padding-left: 18px;
    padding-right: 18px;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top:5px;
}
.service-thumb {
    width: 35px;
    height: 35px;
    margin-right: 16px;
    object-fit: contain;
}
.service-price {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: var(--orange)
}
.service-main-title{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: left;
    color: var(--dark);
    margin-bottom: 6px;
}
.service-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: #3C4F74;
}