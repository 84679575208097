.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.med247footer {
  height: 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: black;
  color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  text-align: center;
  width: 100%;
}
.footer {
  /* height: 200px; */
  width: 100%;
  background-color: black;
  color: white;
  padding: 20px;
  padding-bottom: 80px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  text-align: left;
  width: 50%;
  color:white;
}
.title-ticket {
  text-overflow:ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.slim-text {
  font-size: 9pt;
  color:var(--text);
  font-weight: 400;
}
.slim-text-white {
  font-size: 9pt;
  color:white;
  font-weight: 400;
}
.bold-text {
  font-size: 11pt;
  color:var(--text);
  font-weight: 700;
}
.bold-text-white {
  font-size: 11pt;
  color:white;
  font-weight: 700;
}
.mid-text {
  font-size: 11pt;
  color:var(--text);
  font-weight: 500;
}
.mid-text-white {
  font-size: 11pt;
  color:white;
  font-weight: 500;
}
.no-margin{
  margin: 0 !important;
}
.no-padding{
  padding: 0 !important;
}
.tiny-text {
  font-size: 8pt;
  color:var(--text);
  font-weight: 400;
}
.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 !important;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  /* background: #dbe3e8a1; */
  background: #023164d6;
  border: 1px solid #0551bf;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 10px 10px 0 0 !important;
  color:white !important;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  /* color: #1890ff; */
  color: white !important;
  background: #030f21 !important;
  font-weight: 500;
}
.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 !important;
  position: sticky;
  top: 0;
  z-index: 6666;
}
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #0751bf99;
  content: '';
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #078eff24;
  border: 1px solid #0751bf;
  border-radius: 2px;
  color:#24b4f6;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  color: #24b4f6;
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: #24b4f6;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.ant-col {
  position: relative;
  min-height: 1px;
  background: #030f21cf;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: transparent;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border: none !important;
  background: #0299d5 !important;
  border-bottom-color: transparent;
  /* border-radius: 0 !important; */
  color: black !important;
}
.ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  background: #ffffff !important;
}
.board {
  background-color: #72c9ee21;
  margin: 10px;
  margin-left:4px;
  margin-right: 4px;
  border-radius:6px;
  padding:8px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #0d51bf;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fafafa;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background-color: transparent;
  border: 1px solid rgb(13 81 191);
  border-bottom: 0;
  border-radius: 5px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  color: white;
  line-height: 1.5715;
  background-color: #022b62;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 8px 8px;
}
.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 8px 8px 8px 8px;
}
.doctor-panel {
  color: white;
}
.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  color: #2cb2f5;
}
.flex-verti {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.center {
  align-items: center;
}
.ant-comment-inner {
  display: flex;
  padding: 10px 0;
}
.link-underlined {
  font-size: 14pt;
  display: inline-block;
  border-bottom: 1px solid rgba(216, 223, 233, 0.8);
  -webkit-transition: border-color 400ms cubic-bezier(.25, .46, .45, .94), color 400ms cubic-bezier(.25, .46, .45, .94);
  transition: border-color 400ms cubic-bezier(.25, .46, .45, .94), color 400ms cubic-bezier(.25, .46, .45, .94);
  color: rgba(216, 223, 233, 0.8);
  line-height: 150%;
  text-decoration: none;
}
.block-footer {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d; opacity: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-weight: bold;
}
.heading-footer {
  margin-top: 4px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 18px;
  line-height: 140%;
  font-weight: 600;
  font-family: Inter, sans-serif;
}
.link-footer {
  padding-top: 6px;
  padding-bottom: 6px;
  -webkit-transition: color 400ms cubic-bezier(.25, .46, .45, .94);
  transition: color 400ms cubic-bezier(.25, .46, .45, .94);
  color: #616d7e;
  font-size: 16px;
  line-height: 140%;
  font-weight: 500;
  text-decoration: none;
  font-family: Inter, sans-serif;
}
