.box-filter-control {
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
}
.full-day-header {
    margin-bottom: 2px; 
    text-overflow: ellipsis; 
    overflow: hidden; 
    width: 100%; 
    white-space: nowrap; 
    font-size: 10px; 
    color: black; 
    opacity: 0.5;
    letter-spacing: -0.2px;
}
.doctor-item {
    padding: 0;
    padding-bottom: 36px;
    margin-left: 0;
    margin-right: 0; 
    margin-bottom: 10px;
    position: relative;
}
.doctor-item-top {
    background-color: var(--clinic-l3);
    width: 100%; 
    padding: 16px;
    padding-bottom: 10px; 
    justify-content: flex-start;
}
.pick-box {
    background: #49D6D6;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    height: 40px;
    position: relative;
    color:white;
    font-weight: bold;
    font-size: 12pt;
    padding: 6px;
    width: 96%;
    margin:8px;
    margin-bottom:20px;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    height: 40px ;
}
.select-header {
    background: #49D6D6;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    height: 40px;
    position: relative;
    color:white;
    font-weight: bold;
    font-size: 12pt;
    padding: 6px;
    width: 100%;
    position: absolute;
    top:0;
    left: 0;
}
.disabled {
    color: lightgray !important;
}
.control-button {
    width:35px;
    color:#92D5DA; 
    font-size:20pt;
    background-color: white;
    z-index: 9999;
}
.input-date {
    background-color: transparent;
    width: 100%;
    height: 1px;
    position: fixed;
    bottom: 0px;
    left: 0;
    text-align: center;
    font-weight: bold;
    font-size: 16pt;
    color: white;
    opacity: 0;
}
.blur {
    opacity: 0.3;
}
.chuyen-khoa{
    font-size:8pt;
    font-weight:600;
    text-align:left;
    text-overflow:ellipsis;
    white-space:nowrap;
    width: calc(100% + 12px); 
    overflow: hidden;
}
.doctor-position {
    font-weight: 500;
    font-size: 8.4pt;
}
.doctor-name {
    font-weight: 700;
    font-size: 10.5pt;
    text-align:left;
}
.avatar-doctor {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    margin-bottom: 10px;
}
.dtime{
    width: 100vw;
    height: auto;
    overflow: hidden;
    position: relative;
    /* overflow-x: scroll; */
}
.day-header-container {
    width: calc(100vw - 70px);
    position: relative;
    /* overflow:hidden; */
}
.day-header {
    width:-webkit-fill-available;
    overflow: hidden; 
    width: 100%;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10pt; 
    color: black;
    line-height: 22px;
}
.dtime-column {
    padding:0;
    padding-left: 2px;
    padding-right: 2px;
    width: calc( (100vw - 70px) / 4);
    /* width: 100px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
/* .doctor-detail .dtime-column { */
    /* width: calc( (100vw - 70px) / 7); */
/* } */
.time-box {
    width:-webkit-fill-available;
    height:34px;
    color: white;
    border-radius: 5px;
    margin: 4px;
    margin-left: 1px;
    margin-right: 1px;
    justify-content: center;
    display: flex;
    align-items: center;
}
.dtime-scroll {
    width: calc(100% - 70px + 14px);
    height: 30vh;
    margin-left: 35px;
    
    overflow-y:scroll;
    overflow-x: hidden;
    /* scroll-behavior: smooth; */
    position: relative;
}
.time-content {
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
}
.dtime-scroll::-webkit-scrollbar {
    width: 9px;
}
.dtime-scroll::-webkit-scrollbar-track {
    background-color: white;
}
 
.dtime-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #87E4E4;
    background-image: url('data:image/svg+xml;utf8,<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="5" height="2" rx="1" fill="white"/><rect y="3" width="5" height="2" rx="1" fill="white"/><rect y="6" width="5" height="2" rx="1" fill="white"/></svg>');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    /* border: 10px solid white; */
    /* border-left-width: 6px; */
    /* border-right-width: 6px; */
    padding: 10px;
}

.clinic-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding:5px;
    padding-left: 18px;
    padding-right: 18px;
    background: #FFFFFF;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top:5px;
}
.clinic-address {
    font-size: 9pt;
    font-weight: 400;
    font-style: italic;
    color:rgba(128, 128, 137, 1)
}
.box-booking-doctor-detail {
    background: #FAFAFA;
    border: 1px solid rgba(196, 196, 196, 0.5);
    box-sizing: border-box;
    padding: 20px 15px;
}
.box-booking-doctor-detail .control-button {
    background: none;
}
.box-booking-doctor-detail .dtime-column {
    width: 100px;
}
.box-booking-doctor-detail .dtime-scroll {
    margin-left: 26px;
}
.box-patient-info input.ant-input {
    background: #FFFFFF;
    border: 1px solid rgba(196, 196, 196, 0.5);
    box-sizing: border-box;
    border-radius: 1px;
    height: 50px;
}
.box-book-time .book-time-form {
    background: #FFFFFF;
    border: 1px solid rgba(196, 196, 196, 0.5);
    box-sizing: border-box;
    border-radius: 1px;
    padding: 15px 0px;
}
.form-doctor-detail-booking button[type="submit"] {
    background: #FFDA55;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    text-transform: uppercase;
    border: 0;
    margin-top: 30px;
    margin-bottom: 40px;
    padding: 10px 25px;
    height: 40px;
    color: rgba(60, 79, 116, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}
.box-examination-booking-info {
    padding-left: 15px;
    padding-right: 15px;
}
.box-examination-booking-info .ant-form-item-control-input-content {
    text-align: left;
}
.box-examination-booking-info .ant-form-item-control-input-content .ant-radio-wrapper {
    text-transform: uppercase;
    font-size: 16px;
}
.box-examination-booking-info .ant-radio-wrapper:hover .ant-radio,
.box-examination-booking-info .ant-radio:hover .ant-radio-inner,
.box-examination-booking-info .ant-radio-input:focus + .ant-radio-inner {
    border-color: rgba(60, 79, 116, 1);
}
.box-examination-booking-info .ant-radio-inner::after {
    background-color: #FFDA55;
}
.box-examination-booking-info .ant-radio-checked .ant-radio-inner {
    border-color: rgba(60, 79, 116, 1);
}
.p-description {
    white-space: break-spaces;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #3C4F74;
    padding-bottom: 20px;
    margin: 0
}
.h-title {
    text-transform: uppercase;
    color: #3C4F74;
    letter-spacing: 0.25px;
    font-weight: 500;
    position: relative;
    margin-bottom: 20px;;
    padding-top: 20px;
}
.h-title::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    width: 139px;
    height: 5px;
    background: #FFC908;
    border-radius: 2px;
}
.box-patient-info .booking-clinic .ant-select-selector .ant-select-selection-placeholder{
    text-align: start;
    padding-left: 10px;

}

@media screen and (min-width: 768px) {
    .box-book-time {
        padding-left: 15px;
    }
    .box-book-time .book-time-form {
        padding: 15px 10px;
    }
}
.doctor-detail-booking-page {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    max-width: 1700px;
    margin: auto;
}

.text-normal {
    font-weight: normal;
}
.mx-3 {
    margin-left: 15px;
    margin-right: 15px;
}
.pb-3 {
    padding-bottom: 15px;
}
.modal-center {
    top: 50% !important;
    transform: translateY(-100%)
}
@media screen and (max-width: 767px) {
    .modal-center {
        top: 100% !important;
    }
}
.modal-center .ant-modal-confirm-body-wrapper,
.modal-center .ant-modal-confirm-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.modal-center .ant-modal-confirm-body-wrapper .ant-modal-confirm-body > .anticon {
    margin-bottom: 16px;
    margin-right: 0;
}
@media screen and (max-width: 767px){
    .h-title {
        font-size: 17px;
        margin: 0 20px 20px 20px;
        padding-bottom: 10px;
    }
    .p-description {
        padding: 0 20px 20px 20px
    }
    .examination-offline {
        padding: 15px 0;
    
    }
    .box-examination-booking-info .ant-form-item-control-input-content .ant-radio-wrapper {
        font-size:14px
    }
    .book-time-form .text-today {
        text-align: center;
        font-size: 12px
    }
}

.booking-clinic.ant-select-single .ant-select-selector .ant-select-selection-item {
    text-align: left;
    padding-left: 10px;
}