body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --bg-gray:#FAFAFA;
  --danger:#E2857F;

  --clinic-dark:#27ACAC;
  --clinic-d3:#49D6D6;
  --clinic-d2:#5DDBDB;
  --clinic-d1:#72DFDF;
  --clinic:#87E4E4;
  --clinic-l1:#9CE9E9;
  --clinic-l2:#B1EDED;
  --clinic-l3:#C5F2F2;
  --clinic-l4:#D1F2F6;

  --blue-d3:#1A66FF;
  --blue-d2:#3377FF;
  --blue-d1:#4D88FF;
  --blue:#6699FF;
  --blue-l1:#80AAFF;
  --blue-l2:#99BBFF;
  --blue-l3:#B3CCFF;

  --yellow-d3:#BB9200;
  --yellow-d2:#D4A600;
  --yellow-d1:#EEBA00;
  --yellow:#FFC908;
  --yellow-l1:#FFCF22;
  --yellow-l2:#FFD43B;
  --yellow-l3:#FFDA55;

  --orange-d3:#E2650A;
  --orange-d2:#F57110;
  --orange-d1:#F67F29;
  --orange:#F78E41;
  --orange-l1:#F89D59;
  --orange-l2:#F9AB72;
  --orange-l3:#FABA8A;

  --dark-d3:#080B10;
  --dark-d2:#111620;
  --dark-d1:#192231;
  --dark:#222D42;
  --dark-l1:#2B3853;
  --dark-l2:#334464;
  --dark-l3:#3C4F74;

  --text:#3C4F74;

  --red-d3:#AC2E27;
  --red-d2:#C1342B;
  --red-d1:#D13D34;
  --red:#D65149;
  --red-l1:#DB655E;
  --red-l2:#DF7973;
  --red-l3:#E48D87;

  --green-d3:#27AC5C;
  --green-d2:#2BC167;
  --green-d1:#34D173;
  --green:#49D681;
  --green-l1:#5EDB8F;
  --green-l2:#73DF9E;
  --green-l3:#87E4AC;
}
