[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  /* background: ; */
  border: 0px;
  border-radius: 2px;
}
.datepicker-modal {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 10002 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    padding-left: 20px;    
    padding-right: 40px;
    display: flex;
    flex-direction: row;
    height: min-content; 
    background: #FFFFFF;
    border: 0.5px solid rgba(196, 196, 196, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    align-items: center;
}
.ant-collapse-ghost > .ant-collapse-item {
    background-color: var(--blue-l3);
    margin-bottom: 15px;
    border-radius: 20px;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.2), inset 0px 1px 1px rgba(0, 0, 0, 0.14), inset 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.ant-collapse-borderless > .ant-collapse-item:last-child, .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
    border-radius: 20px;
}
.ant-picker-panel-container .ant-picker-panel {
    vertical-align: top;
    background: transparent;
    border-width: 0 0 1px 0;
    border-radius: 0;
    width: 100%;
}
.ant-picker-panel-container {
    overflow: hidden;
    vertical-align: top;
    background: transparent;
    border-radius: 2px;
    box-shadow: none;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: margin 0.3s;
}
.ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.ant-picker-header > button {
    min-width: 1.6em;
    font-size: 14px;
    width: 50px;
}
.ant-picker-date-panel .ant-picker-content {
    width: 100%;
}
.ant-input-number-input {
    width: 100%;
    height: 30px;
    padding: 0 11px;
    text-align: center;
    background-color: transparent;
    border: 0;
    border-radius: 5px;
    outline: 0;
    transition: all 0.3s linear;
    -moz-appearance: textfield !important;
}
.ant-dropdown {
    z-index: 10002 !important;
}
.ant-select-dropdown {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum",;
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 9999;
    box-sizing: border-box;
    padding: 4px 0;
    overflow: hidden;
    font-size: 14px;
    font-variant: initial;
    background-color: #fff;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}
.ant-modal-wrap {
    z-index: 11111 !important;
}
.ant-modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11111;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
}
.ant-modal {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    pointer-events: none;
    position: relative;
    top: 100px;
    width: auto;
    max-width: calc(100vw - 32px);
    margin: 0 auto;
    padding-bottom: 24px;
    border-radius: 10px;
    overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display: block;
    width: 100px;
    height: 100px;
    overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    width: 100px;
    height: 100px;
    line-height: 54px;
    text-align: center;
    opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
    position: relative;
    height: 116px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    overflow: hidden;
}
.overlayClassName {
    background-color: red;
}
.overflow-hidden {
    overflow: hidden;
}
.ant-carousel .slick-dots li.slick-active button {
    background: #F78E41;
    opacity: 1;
}
.ant-carousel .slick-dots li button {
    display: block;
    width: 10px;
    height: 10px;
    padding: 0;
    color: transparent;
    font-size: 0;
    background: #fff;
    border: 0;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    opacity: 0.3;
    transition: all 0.5s;
}
.ant-carousel .slick-dots li.slick-active {
    width: 10px;
    background-color: #F78E41;
    height: 10px;
    border-radius: 10px;
}

.ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    box-sizing: content-box;
    border: 2px solid #F78E41;
    border-radius: 10px;
    height: 10px;
    width: 10px;
    margin: 0 2px;
    margin-right: 3px;
    margin-left: 3px;
    padding: 0;
    text-align: center;
    text-indent: -999px;
    vertical-align: top;
    transition: all 0.5s;
}
.ant-carousel .slick-dots-bottom {
    bottom: -24px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 32px;
    padding-left: 10px;
}